.front-page {

    .home {
        &__title {
            display: inline-block;
            position: relative;
            color: $color-main;
            font-size: 2.813rem;
            line-height: 3.125rem;
            padding: 0;
        }
    }

    &__section {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto 0;
            width: calc(50% + 285px);
            background-color: $color-light;
        }

        .container {
           display: flex;
           justify-content: space-between;
        }
    }
}

@keyframes waveAnim {
    from { 
        background-position-x: 0;
    }
    to { 
        background-position-x: 30px;
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
        }
    }

    .slideshow {
        height: 500px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 800px;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            position: absolute;
            top: 30px;
            right: 0;
            width: 370px;
            height: 280px;
            background: $color-bg--transparent;
            padding: 0;
        }

        &__title {
            position: relative;
            margin-bottom: 20px;
            font-size: 2.813rem;
            line-height: 3.125rem;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
            color: $color-main;
            padding-left: 30px;
            
            &:before {
                content: "";
                position: absolute;
                width: 125px;
                height: 30px;
                left: -55px;
                bottom: -50px;
                background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        &__description {
            font-size: 1.125rem;
            line-height: 1.5rem;
            padding-left: 85px;
        }

        &--video {
            img {
                display: none;
            }
        }

        .container--navigation {
            position: absolute;
            right: 175px;
            bottom: 120px;
        }

        // Navigation (arrow)
        &__navigation {
            position: relative;
            z-index: 1;
            width: 110px;
            height: 50px;
            pointer-events: none;
            
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                height: 1px;
                width: 195px;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto 0;
                background-color: $color-second;
            }
    
            &__btn {
                pointer-events: auto;
                width: 50px;
                height: 50px;
                margin: 0;
                padding: 0;
                background: $color-white;
                border: 1px solid $color-second;
                border-radius: 5$border-radius--round;

                & svg {
                    width: 50px;
                    height: 50px;
                    fill: $color-main;
                }

                &:after {
                    content: unset;
                }
    
                &:hover {
                    background-color: $color-second;
    
                    & svg {
                        fill: $color-main;
                    }
                }

                &--prev {
                    top: 0;
                    left: 0;
                }

                &--next {
                    top: 0;
                    right: 0;
                }
            }
        }    
    }
}


//======================================================================================================
// Access
//======================================================================================================
.access {
    &__section {
        position: relative;
        z-index: 0;
        margin-top: -70px;
        padding: 140px 0 95px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            bottom: 0;
            width: calc(50% + 585px);
            background-color: $color-main;
            margin: auto 0;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__heading {

        &__title.home__title {
            color: $color-white;

            & span {
                display: block;
                padding-left: 90px;
            }

            &:before, &:after {
                content: "";
                position: absolute;
            }

            &:before {
                width: 125px;
                height: 30px;
                left: -55px;
                bottom: 10px;
                background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
                background-position: center;
                background-size: contain;
            }

            &:after {
                width: 10px;
                height: 26px;
                right: -20px;
                bottom: 10px;
                background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
                background-position: center;
                background-size: contain;
            }
        }
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 790px;
        margin-right: 100px;
    }

    &__item {
        &__link {
            position: relative;
            z-index: 0;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 15px 0;
            align-items: center;
            text-align: center;
            width: 115px;
            height: 135px;
            color: $color-white;
            font-size: $font-size--text-small;
            line-height: 1.125rem;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;

            &:hover, &:focus {
                color: $color-white;

                .access__item__picto {
                    fill: $color-main;
                }

                .access__item__bg {
                    fill: $color-second;
                    transform: rotate(90deg);
                }
            }
        }

        &__picto {
            width: 85px;
            height: 85px;
            fill: $color-white;
            transition: all .5s ease-in-out;
        }

        &__bg {
            position: absolute;
            z-index: -1;
            width: 85px;
            height: 85px;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            stroke: $color-second;
            fill: $color-bg--transparent;
            transition: all .5s ease-in-out;
        }
    }
}



//======================================================================================================
// Post
//======================================================================================================
.posts {
    &__section {
        position: relative;
        margin: 120px 0;

        &:before, &::after {
            content: "";
            position: absolute;
            width: 420px;
            height: 8px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_animation/vague_petite.png');
            background-repeat: repeat-x;
            animation-name: waveAnim;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        &:before {
            left: calc(50% + 330px);
            bottom: 30px;
        }

        &:after {
            left: calc(50% + 300px);
            bottom: 13px;
        }
    }

    &__title {
        margin: 20px 0 0 30px;

        & span {
            display: block;
            padding-left: 100px;
        }

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before {
            width: 125px;
            height: 30px;
            left: -55px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
            background-position: center;
            background-size: contain;
        }

        &:after {
            width: 10px;
            height: 26px;
            right: -20px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
            background-position: center;
            background-size: contain;
        }
    }

    &__list-small .swiper-slide {
        width: 470px!important;
        margin-right: 30px!important;
    }

    &__item {
        display: inline-block;
        width: 100%;

        &--0 {
            display: flex;
            position: relative;
            margin-bottom: 60px;

            .posts {

                &__item {
                    &__content {
                        position: absolute;
                        right: 0;
                        top: 150px;
                        width: 470px;
                        height: auto;
                        max-height: 245px;
                        padding: 45px 50px;
                        background-color: $color-main;
                    }

                    &__container-img {
                        height: 425px;
                        width: 770px;
                        margin: 0;

                        &:before {
                            content: unset;
                        }

                        & img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    &__title {
                        font-size: 1.75rem;
                        line-height: 1.875rem;
                        padding: 0;
                        margin: 0 0 20px;

                        &:before {
                            content: "";
                            width: 60px;
                            transition: all .5s ease-in-out;
                        }
                    }

                    &__title, &__intro {
                        color: $color-white;
                    }
                }
            }

            &:hover {
                .posts {
                    &__item {
                        &__title {
                            &:before {
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }

        &__container-img {
            position: relative;
            height: 250px;
            width: 100%;
            margin-bottom: 15px;
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
            background-position: center;

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 80px;
                height: 3px;
                background-color: $color-second;
                transition: all .5s ease-in-out;
            }

            & img {
                height: 100%;
                object-fit: cover;
            }
        }

        &:hover {
            .posts__item__container-img {
                &:before {
                    width: 100%;
                }
            }
        }
    
        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            margin: 0 0 10px 0;
    
            &:before {
                content: none;
            }
        }
    }

    &__list {
        &-small {
            .swiper-container {
                width: 970px;
                margin: 0;
                overflow: visible;
            }
        }
    }

    &__template {
        display: flex;
        margin-top: 30px;

        button, &__btn {
            margin: 0;

            &.swiper-button {
                width: 50px;
                height: 50px;
                padding: 0;
                top: 0;
                background-color: $color-white;
                border-color: $color-second;
                
                &.swiper-button-disabled {
                    opacity: 1;

                    & svg {
                        opacity: .35;
                    }
                }

                & svg {
                    width: 50px;
                    height: 50px;
                    fill: $color-main;
                }

                &:after {
                    content: unset;
                }

                &-prev {
                    left: 0;
                }

                &-next {
                    right: 0;
                }

                &:hover {
                    background-color: $color-second;
                }
            }
        }
    }

    &__navigation {
        position: relative;
        z-index: 0;
        width: 110px;
        height: 50px;
        margin-right: 30px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 10px;
            height: 1px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: $color-second;
        }

        &.disable {
            display: none;
        }
    }
}



//======================================================================================================
// Events
//======================================================================================================
.events {
    &__section {
        position: relative;
        padding: 80px 0 70px;
        background-color: $color-main;

        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            bottom: 0;
            width: calc(50% - 285px);
            background-color: $color-main;
        }

        .container {
            display: flex;
        }

        .swiper-container {
            width: 630px;
            margin: 0;
            overflow: visible;
        }
    }

    &__heading {
        z-index: 3;
        width: 270px;
        margin-right: 30px;
    }

    &__intro {
        width: 220px;
        color: $color-white;
        margin-bottom: 20px;
    }

    &__button {
        width: 220px;
        margin: 0;

        &:hover {
            color: $color-white;
        }
    }

    &__navigation {
        position: relative;
        z-index: 0;
        margin-top: 35px;
        height: 50px;
        width: 110px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto 0;
            width: 300px;
            height: 1px;
            background-color: $color-second;
        }

        &__btn.swiper-button-prev, &__btn.swiper-button-next {
            width: 50px;
            height: 50px;
            padding: 0;
            margin: 0;
            top: 0;
            background-color: $color-main;
            border-color: $color-second;

            & svg {
                width: 50px;
                height: 50px;
                fill: $color-white;
            }

            &:after {
                content: unset;
            }

            &:hover {
                background-color: $color-second;

                & svg {
                    fill: $color-main;
                }
            }
        }

        &__btn {
            &.swiper-button-prev, &.swiper-button-next {
                &.swiper-button-disabled {
                    opacity: 1;

                    & svg {
                        opacity: .35;
                    }
                }
            }

            &.swiper-button-prev {
                left: 0;
            }

            &.swiper-button-next {
                right: 0;
            }
        }

        &.disable {
            display: none;
        }
    }

    &__title.home__title {
        color: $color-white;

        & span {
            display: block;
            padding-left: 110px;
        }

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before {
            width: 125px;
            height: 30px;
            left: -40px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
            background-position: center;
            background-size: contain;
        }

        &:after {
            width: 10px;
            height: 26px;
            right: -20px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
            background-position: center;
            background-size: contain;
        }
    }

    .swiper-container {
        .swiper-wrapper {
            width: 630px;
            height: 410px;
        }
    }

    .swiper-slide {
        width: fit-content!important;
        margin-right: 30px;
    }

    &__link {
        display: inline-block;
        position: relative;

        &:hover {
            .events__infos__title::before {
                width: 80px;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 0;
        height: 410px;
        width: 300px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-gray;
            background-position: center;
        }
    }

    &__date {
        display: flex;
        align-items: center;
        position: absolute;
        top: 20px;
        left: 20px;

        & svg {
            position: absolute;
            z-index: 1;
            width: 40px;
            height: 40px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        & p {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-family: $font-family--heading;
            position: relative;
            z-index: 0;
            width: 70px;
            height: 70px;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 70px;
                height: 70px;
                background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/forme_bg_dates.svg');
            }

            &:last-child {
                margin-left: -10px;
            }
        }

        &__day {
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: $font-weight-bold;
        }

        &__month {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: $font-weight-light;
        }
    }

    &__infos {
        position: absolute;
        padding: 0 25px;
        bottom: 0;

        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            color: $color-white;

            &:before {
                top: -18px;
                width: 40px;
                bottom: unset;
                transition: all .5s ease-in-out;
            }
        }

        &__category {
            color: $color-white;
            font-size: 1.25rem;
            line-height: 1.5rem;
            text-transform: uppercase;
        }
    }
}



//======================================================================================================
// Loisirs
//======================================================================================================
.loisirs {

    &__section {
        margin: 120px 0;

        .admin-blocs {
            display: flex;
            flex-wrap: wrap;
            height: 470px;

            .bloc {
                position: relative;
                width: 270px;
                height: 220px;
                overflow: hidden;

                & a.bloc__wrapper {
                    &:hover {

                        .bloc__title {
                            &:after {
                                transform: rotate(45deg);
                                opacity: 1;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    order: 1;
                    width: 670px;
                    margin-bottom: 30px;
                }

                &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                    order: 3;
                }

                &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    margin-right: 30px;
                }

                &__image {
                    position: relative;
                    z-index: 0;
                    height: 220px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
                    }

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &__content {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 20px 15px;
                }

                &__title {
                    position: relative;
                    z-index: 0;
                    width: 140px;
                    font-size: 1.125rem;
                    line-height: 1.375rem;
                    font-family: $font-family--heading;
                    font-weight: $font-weight-bold;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 7px;
                        left: -9px;
                        height: 10px;
                        width: 4px;
                        background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_loisirs.svg');
                        background-position: center;
                        background-size: contain;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: -45px;
                        left: -40px;
                        width: 200px;
                        height: 200px;
                        background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/forme_bg_dates.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        opacity: .9;
                        transition: all .5s ease-in-out;
                    }
                }
            }
        }
    }

    &__heading {
        z-index: 1;
        order: 2;
        width: 440px;
        margin-left: 60px;
    }

    &__intro {
        width: 375px;
        padding-left: 40px;
    }
    
    &__title.home__title {
        margin: 30px 0 15px;

        & span {
            display: block;
            padding-left: 40px;
        }

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before {
            width: 125px;
            height: 30px;
            left: -100px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
            background-position: center;
            background-size: contain;
        }

        &:after {
            width: 10px;
            height: 26px;
            right: -20px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
            background-position: center;
            background-size: contain;
        }
    }
}



//======================================================================================================
// Annuaire
//======================================================================================================
.annuaire {

    &__section {
        display: flex;
        margin: 85px 0 100px;
    }

    &__container {
        width: 400px;
        margin-right: 80px;
    }

    &__title.home__title {
        margin: 0 0 35px 0;

        & span {
            display: block;
            padding-left: 110px;
        }

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before {
            width: 125px;
            height: 30px;
            left: -40px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
            background-position: center;
            background-size: contain;
        }

        &:after {
            width: 10px;
            height: 26px;
            right: -20px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
            background-position: center;
            background-size: contain;
        }
    }

    &__heading {
        & p {
            width: 370px;
            margin-bottom: 20px;
        }
    }

    &__template {
        &-btn {
            width: 350px;
            margin: 0 0 15px 0;
        
            &:last-child {
                margin: 0;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 135px;
    }

    &__button {
        display: flex;
        position: relative;
        z-index: 0;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $color-text;
        transition: all .5s ease-in-out;

        &:first-child {
            margin-bottom: 35px;
        }

        & svg {
            width: 85px;
            height: 85px;
            margin-bottom: 10px;
            fill: $color-main;
            transition: all .5s ease-in-out;

            &:nth-child(2) {
                position: absolute;
                z-index: -1;
                left: 0;
                right: 0;
                margin: 0 auto;
                fill: $color-bg--transparent;
                stroke: $color-main;
                opacity: .4;
            }
        }

        & span {
            font-size: 0.938rem;
            line-height: 1.125rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
        }

        &:hover {

            & svg {
                &:nth-child(2) {
                    fill: $color-second;
                    stroke: $color-second;
                    transform: rotate(90deg);
                    opacity: 1;
                }
            }
        }
    }
}



//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        margin: 85px 0;

        &:before, &::after {
            content: "";
            position: absolute;
            width: 330px;
            height: 8px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_animation/vague_petite.png');
            background-repeat: repeat-x;
            animation-name: waveAnim;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        &:before {
            left: calc(50% + 510px);
            top: 120px;
        }

        &:after {
            left: calc(50% + 480px);
            top: 135px;
        }
    }

    &__item {
        display: flex;
    }
    
    &__image {
        width: 230px;
        height: 325px;
        transform: rotate(6deg);
        margin-right: 30px;
    }

    &__content {
        width: 185px;
        margin-top: 115px;
    }

    &__filesize {
        text-transform: uppercase;
    }

    &__info {
        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-weight: $font-weight-bold;
            color: $color-text;
            margin: 8px 0 10px;

            &:before {
                content: none;
            }
        }
    }

    &__buttons {
        font-size: 0;
    }

    &__btn {
        display: inline-block;
        margin: 0 10px 0 0;
        
        & svg {
            width: 50px;
            height: 50px;
            border: $border-width $border-style $color-gray;
            border-radius: $border-radius--round;
            transition: all .5s ease-in-out;
        }
        
        &:last-child {
            margin: 0;
        }

        &:hover {
            & svg {
                background-color: $color-gray;
            }
        }
    }
}



//======================================================================================================
// Social Wall
//======================================================================================================
.sw {

    &__section {
        position: relative;
        padding: 80px 0 150px;
        background-color: $color-main;

        &:before, &::after {
            content: "";
            position: absolute;
            width: 420px;
            height: 8px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_animation/vague_petite.png');
            background-repeat: repeat-x;
            animation-name: waveAnim;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        &:before {
            left: calc(50% - 820px);
            bottom: 80px;
        }

        &:after {
            left: calc(50% - 850px);
            bottom: 65px;
        }
    }

    &__container {
        display: flex;
    }

    &__heading {
        z-index: 1;
        width: 245px;
        margin-top: 100px;
        margin-left: 30px;
    }

    &__title.home__title {
        color: $color-white;
        margin: 0 0 20px 0;

        & span {
            display: block;
            padding-left: 30px;
        }

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before {
            width: 125px;
            height: 30px;
            left: -110px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
            background-position: center;
            background-size: contain;
        }

        &:after {
            width: 10px;
            height: 26px;
            right: -20px;
            bottom: 10px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
            background-position: center;
            background-size: contain;
        }
    }

    &__buttons {
        display: flex;
        margin-top: 15px;
        margin-left: 30px;
    }

    &__btn {
        & svg {
            width: 50px;
            height: 50px;
            fill: $color-white;
            border: $border-width $border-style $color-second;
            border-radius: $border-radius--round;
            transition: all .5s ease-in-out;
        }

        &:hover {
            & svg {
                background-color: $color-second;
                fill: $color-main;
            }
        }

        &:first-child {
            margin-right: 10px;
        }
    }

    &__intro {
        color: $color-white;
        margin-left: 30px;
    }

    #ff-stream-1 {
        z-index: 0;
        width: 870px;
        height: 385px!important;
        min-height: 385px!important;
        background-color: $color-bg--transparent!important;
        padding: 0;

        .ff-stream-wrapper {
            width: 930px;
            margin: 0 -30px;
            margin-top: 0;
            padding: 0!important;

            .ff-item {

                .picture-item__inner {
                    background: transparent!important;
                    box-shadow: unset!important;

                    &:hover {
                        &:before {
                            background: transparent!important;
                        }
                    }
                }

                .ff-img-holder {
                    height: 230px!important;
                }

                .ff-content {
                    position: relative;
                    height: auto !important;
                    max-height: 335px!important;
                    padding-top: 60px;
                    margin: 0;
                    color: $color-white !important;
                    overflow: visible;

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -13px;
                        left: 0;
                        width: 40px;
                        height: 3px;
                        background-color: $color-second;
                        transition: all .5s ease-in-out;
                    }
                }

                .ff-img-holder + .ff-content {
                    position: relative;
                    height: auto !important;
                    max-height: 120px!important;
                    padding-top: 10px;
                    margin: 0;
                    color: $color-white !important;
                    overflow: visible;

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -13px;
                        left: 0;
                        width: 40px;
                        height: 3px;
                        background-color: $color-second;
                        transition: all .5s ease-in-out;
                    }
                }

                &:hover {
                    .ff-img-holder + .ff-content {
                        &:before {
                            width: 80px;
                        }
                    }
                }

                .ff-item-cont {
                    height: 345px !important;

                    .ff-content {
                        font-size: $font-size--text;

                        a {
                            &:hover {
                                color: $color-second!important;
                                text-decoration: unset!important;
                            }
                        }
                    }
                }

                .ff-icon-inner {
                    font-family: $font-family--heading!important;
                    background-color: $color-white!important;
                    border-radius: 5px!important;
                    color: $color-text!important;
                }

                .ff-item-bar {
                    padding: 20px 0 0!important;
                    height: 40px!important;
                    border: 0!important;
                    color: $color-white!important;
                }
            }
        }

        .ff-item-meta, .ff-loadmore-wrapper {
            display: none;
        }

        .ff-share-popup {
            right: -10px!important;
            background-color: $color-main!important;

            &::after {
                right: 8px !important;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {
        &__section {
            &:before {
                width: calc(50% + 230px);
            }
        }
    }

    // ALU
    .home-slideshow {

        .slideshow {
            height: 400px;

            &__content {
                width: 280px;
                height: 230px;
            }

            .container {
                &--navigation {
                    right: 115px;
                    bottom: 90px;
                }
            }

            &__title {
                padding: 0;
                font-size: 2.375rem;
                line-height: 2.75rem;
                margin-bottom: 15px;

                &:before {
                    width: 100px;
                    height: 24px;
                    left: -60px;
                    bottom: -40px;
                }
            }

            &__description {
                padding-left: 55px;
                font-size: $font-size--text;
            }

            img {
                width: 640px;
            }

            &__navigation {
                &:before {
                    width: 185px;
                }
            }
        }
    }

    // Access
    .access {

        &__heading {
            &__title.home__title {
                & span {
                    padding-left: 35px;
                }

                &:before {
                    width: 100px;
                    height: 24px;
                    left: -75px;
                }

                &:after {
                    right: -10px;
                }
            }
        }

        &__list {
            margin-right: 40px;
            width: 740px;
        }
    }

    // Posts
    .posts {

        &__section {
            margin: 100px 0;

            &:before {
                left: calc(50% + 280px);
            }

            &::after {
                left: calc(50% + 250px);
            }
        }

        &__title.home__title {
            font-size: 2.813rem;
            line-height: 3.125rem;
            margin: 10px 0 0 20px;

            &:before {
                width: 100px;
                height: 24px;
                left: -40px;
            }

            span {
                padding-left: 80px;
            }
        }

        &__list-small .swiper-slide {
            width: 380px!important;
            margin-right: 20px!important;
        }

        &__item {
            &--0 {

                & .posts {
                    &__item {
                        &__container-img {
                            width: 620px;
                            height: 390px;
                            margin: 0;
                        }

                        &__content {
                            width: 450px;
                            max-height: 240px;
                            padding: 40px;
                            top: 130px;
                        }
                    }
                }
            }

            &__title {
                font-size: 1.375rem;
                line-height: 1.5rem;
            }

            &__container-img {
                height: 200px;
            }
        }

        &__list-small {
            .swiper-container {
                width: 780px;
            }
        }
    }

    // Events
    .events {

        &__section {

            &:before {
                width: calc(50% - 210px);
            }

            .swiper-container {
                width: 620px;

                .swiper-wrapper {
                    width: 620px;
                }
            }
        }

        &__heading {
            margin-right: 40px;
            width: 220px;
        }

        &__title.home__title {

            span {
                padding-left: 90px;
            }

            &:before {
                width: 100px;
                height: 24px;
            }
        }

        &__navigation {
            &:before {
                width: 260px;
            }
        }

        .swiper-slide {
            margin-right: 20px;
        }
    }

    // Loisirs
    .loisirs {

        &__section {
            margin: 100px 0;

            .admin-blocs {
                height: 400px;
                
                .bloc {
                    height: 180px;
                    width: 220px;

                    &__image {
                        height: 180px;
                    }

                    &:nth-child(2) {
                        width: 540px;
                        height: 200px;
                        margin-bottom: 20px;

                        .bloc {
                            &__image {
                                height: 200px;
                            }
                        }
                    }

                    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                        margin-right: 20px;
                    }
                }
            }
        }

        &__heading {
            margin-left: 20px;
            width: 380px;
        }

        &__title.home__title {
            margin: 25px 0 15px;

            &:before {
                width: 100px;
                height: 24px;
                left: -80px;
            }

            span {
                padding-left: 35px;
            }
        }
    }

    // Annuaire
    .annuaire {

        &__section {
            margin: 70px 0 80px;
        }

        &__title.home__title {
            margin: 0 0 25px;

            &:before {
                height: 24px;
                left: -55px;
                width: 100px;
            }

            & span {
                padding-left: 55px;
            }
        }

        &__heading {
            & p {
                width: 330px;
            }
        }

        &__container {
            margin-right: 50px;
            width: 330px;
        }

        &__template-btn {
            width: 330px;
        }

    }
    // Kiosque
    .kiosque {

        &__section {
            margin: 105px 0 80px;

            &:before {
                left: calc(50% + 410px);
                top: 60px;
            }

            &:after {
                left: calc(50% + 380px);
                top: 75px;
            }
        }

        &__image {
            height: 268px;
            width: 190px;
            margin-right: 15px;
        }

        &__content {
            margin-top: 85px;
            width: 170px;
        }
    }

    // Social Wall
    .sw {

        &__section {
            &:before {
                bottom: 70px;
                left: calc(50% - 730px);
            }

            &:after {
                bottom: 50px;
                left: calc(50% - 760px);
            }
        }

        &__heading {
            width: 220px;
            margin-top: 25px;
            margin-left: 20px;
        }

        &__title.home__title {
            & span {
                padding-left: 20px;
            }

            &:before {
                width: 100px;
                height: 24px;
                left: -90px;
            }
        }

        &__intro {
            width: 190px;
        }

        &__intro, &__buttons {
            margin-left: 20px;
        }

        #ff-stream-1 {
            width: 700px;
            min-height: 330px!important;

            .ff-stream-wrapper {
                width: 740px;
                margin-left: -20px;

                .ff-item {
    
                    .ff-item-cont {
                        height: 330px!important
                    }
                    .ff-img-holder {
                        height: 190px!important;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        &__section {
            &:before {
                width: calc(50% + 310px);
            }
        }
    }
    // ALU
    .home-slideshow {
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 100vw;
            height: 470px;
            top: 310px;
            left: 50%;
            transform: translateX(-50%);
            background-color: $color-light;
        }

        .swiper-container {
            overflow: visible;
        }

        .container {
            &--content {
                margin-top: 80px;
            }

            &--navigation {
                bottom: unset;
                right: unset;
                left: 0;
                top: 400px;
            }
        }

        .slideshow {
            height: auto;
            margin-bottom: 55px;
            font-size: 0;

            &__container {
                
                & img {
                    position: initial;
                    height: 385px;
                }
            }

            &__content {
                position: initial;
                width: 620px;
                height: auto;
            }

            &__navigation {
                &:before {
                    width: 110px;
                }

                &__btn {
                    background-color: $color-light;
                }
            }
        }
    }

    // Access
    .access {

        &__section {
            position: relative;
            z-index: 1;
            margin: 0;
            padding: 60px 0;

            &:before {
                width: calc(50% + 310px);
            }
        }

        &__heading {
            display: none;
        }

        &__list {
            width: 620px;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 0 10px;
            margin-right: 0;
        }

        &__item {
            padding:0 30px;

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                margin-bottom: 40px;
            }
        }
    }

    // Posts
    .posts {

        &__section {
            margin: 80px 0 100px;

            &:before, &::after {
                content: unset;
            }
        }

        &__title.home__title {
            margin: 0;
            
            & span {
                padding-left: 75px;
            }
        }

        &__list-small .swiper-slide {
            width: 300px!important;
        }

        &__item {

            &__heading {
                margin-bottom: 20px;
            }

            &--0 {
                flex-direction: column;
                margin: 0 0 50px;

                .posts {
                    &__item {
                        &__container-img {
                            order: 1;
                        }

                        &__heading {
                            order: 0;
                        }

                        &__content {
                            position: relative;
                            order: 2;
                            width: 560px;
                            max-height: unset;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: -60px auto 0;
                        }
                    }
                }
            }

            &__container-img {
                height: 160px;
            }
        }

        &__list-small {
            .swiper-container {
                width: 100%;
            }
        }
    }

    // Events
    .events {

        &__section {
            padding: 80px 0 170px;

            &:before {
                content: unset;
            }

            .container {
                position: relative;
                flex-direction: column;
            }
        }

        &__heading {
            width: 100%;
            margin:  0 0 20px;
        }

        &__title.home__title {
            margin: 0 0 20px;

            span {
                padding-left: 75px;
            }
        }

        &__intro {
            width: 100%;
            margin: 0;
        }

        &__navigation {
            position: absolute;
            bottom: -90px;

            &:before {
                width: 110px;
            }
        }

        &__buttons {
            position: absolute;
            bottom: -90px;
            left: 150px;
        }
    }

    // Loisirs
    .loisirs {

        &__heading {
            order: 0;
            width: 100%;
            margin: 0;
        }

        &__title.home__title {
            margin: 0 0 15px;
            height: 95px;

            &:before, &:after {
                bottom: 5px;
            }
        }

        &__intro {
            width: 100%;
            margin: 0;
        }

        &__section {
            margin: 80px 0 100px;
            
            .admin-blocs {
                height: 755px;
                gap: 20px;

                .bloc {
                    width: calc(50% - 10px);
                    margin: 0!important;
                    
                    &:nth-child(2) {
                        height: 180px;
                        width: 100%;
                    }
                }
            }
        }
    }

    // Annuaire
    .annuaire {

        &__section {
            flex-direction: column;
            justify-content: space-between;
            margin: 60px 0 75px;

            &:before, &:after {
                content: unset;
            }
        }

        &__container {
            width: 345px;
            margin-right: 0;
        }

        &__heading {
            & p {
                width: 270px;
            }
        }

        &__title.home__title {
            margin-bottom: 40px;

            &:before {
                left: -40px;
            }

            & span {
                padding-left: 75px;
            }
        }

        &__template-btn {
            width: 270px;
            padding: 15px 20px;
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            flex-direction: unset;
            width: 270px
        }

        &__button {
            display: flex;
            flex-direction: column;
            width: 100px;

            &:first-child {
                margin: 0;
                width: 135px;
            }
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            margin: 195px 0 75px;

            &:before, &:after {
                content: unset;
            }
        }

        &__content {
            margin-top: 20px;
            width: 240px;
            margin-left: -15px;
        }

        &__image {
            margin: 0;
        }

        &__item {
            flex-direction: column;
            margin-right: 35px;
        }
    }

    // Social Wall
    .sw {

        &__section {
            padding: 70px 0 120px;

            &:before, &:after {
                content: unset;
            }
        }

        &__container {
            position: relative;
        }

        &__heading {
            position: absolute;
            top: 65px;
            right: 10px;
            margin: 0;
            width: 300px;
        }

        &__title.home__title {
            &:before {
                left: -45px;
            }

            & span {
                padding-left: 70px;
            }
        }

        &__intro, &__buttons {
            margin-left: 70px;
        }

        #ff-stream-1 {
            width: 620px;
            min-height: 810px!important;

            .ff-stream-wrapper {
                width: 640px;
                height: 810px!important;
                margin-left: 0;

                .ff-item {
                    width: 300px !important;
                    height: 395px !important;

                    &:nth-child(3) {
                        transform: translate3d(320px, 415px, 0px) scale3d(1, 1, 1)!important;
                    }

                    .ff-item-cont {
                        height: 360px!important;
                    }

                    .ff-img-holder {
                        height: 240px!important;
                    }
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {

        &__section {
            .container {
                flex-direction: column;
            }
        }

        & .home {
            &__title {
                font-size: 2.5rem;
                line-height: 2.5rem;

                &:after {
                    bottom: 8px!important;
                    height: 20px!important;
                    right: -18px!important;
                    width: 8px!important;
                }
            }
        }
    }

    // ALU
    .home-slideshow {
        margin: unset;
        max-width: 100%;
        padding: unset;

        &:before {
            top: 300px;
            height: 340px;
        }

        .slideshow {
            margin-bottom: 45px;

            &__title {
                font-size: 1.75rem;
                line-height: 2rem;

                &:before {
                    bottom: -35px;
                    height: 14px;
                    left: -55px;
                    width: 60px;
                    
                }
            }

            &__description {
                padding-left: 15px;
            }

            &__content {
                width: 100%;
                max-width: 320px;
                padding: 0 10px;
                margin: 90px auto 0;
            }

            &__container {
                & img {
                    height: 300px;
                }
            }

            & .container {
                &--navigation {
                    max-width: 320px;
                    padding: 0 10px;
                    margin: 0 auto;
                }
    
                &--navigation {
                    left: 0;
                    right: 0;
                    top: 320px;
                }
            }
        }
    }

    // Access
    .access {
        
        &__section {
            padding: 35px 0 50px;

            &:before {
                width: 320px;
                right: 0;
                margin: auto;
            }
        }

        &__item {
            padding: 0;

            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){
                margin-bottom: 30px;
            }
        }
    }

    // Posts
    .posts {
        margin: 60px 0;
        
        &__title.home__title {
            &:before {
                height: 14px;
                width: 60px;
                left: 0;
            }

            & span {
                padding-left: 70px;
            }
        }

        &__item {
            &--0 {
                margin: 0 0 40px;

                .posts {
                    &__item {
                        &__container-img {
                            width: 320px;
                            height: 200px;
                            margin-left: -10px;
                        }

                        &__content {
                            margin-top: -25px;
                            padding: 30px 25px;
                            width: 300px;
                        }
                    }
                }
            }
        }

        &__navigation {
            margin-bottom: 20px;
            margin-right: 0;
        }

        &__template {
            flex-direction: column;
        }

        &__template-btn {
            width: 250px;
        }
    }

    // Agenda
    .events {

        &__section {
            padding: 60px 0 220px;

            .swiper-container {
                width: 100%;
                
                .swiper-wrapper {
                    width: 100%;
                }
            }
        }

        &__title.home__title {
            &:before {
                width: 60px;
                height: 14px;
                left: 0;
            }

            & span {
                padding-left: 70px;
            }
        }

        &__navigation {
            margin: 0;
            top: unset;
            bottom: -90px;
        }

        &__buttons {
            bottom: -160px;
            left: 10px;
        }

        &__button {
            width: 220px;
        }
    }

    // Loisirs
    .loisirs {

        &__title.home__title {

            &:before {
                width: 60px;
                height: 14px;
                left: 0;
            }

            & span {
                padding-left: 70px;
            }
        }

        &__intro {
            padding: 0;
        }

        &__section {

            .admin-blocs {
                height: auto;

                .bloc {
                    width: 100%!important;
                    height: 180px!important;

                    &:last-child {
                        margin: 0!important;
                    }
                }
            }
        }
    }

    // Annuaire
    .annuaire {
        
        &__section {
            margin: 60px 0 0;
        }

        &__container {
            width: 100%;
        }

        &__title.home__title {
            margin-bottom: 20px;

            span {
                padding-left: 30px;
            }

            &:before {
                width: 60px;
                height: 14px;
                left: 160px;
                bottom: 50px;
            }
        }
        
        &__buttons {
            margin-top: 40px;
            width: 265px;
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            position: relative;
            margin: 55px 0 60px;
        }

        &__item {
            margin-left: 10px;
        }

        &__image {
            height: 225px;
            width: 160px;
        }

        &__info {
            &__title {
                margin: 0;
            }
        }

        &__buttons {
            position: absolute;
            right: 40px;
            top: 45px;
            width: 50px;
            height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    // Social Wall
    .sw {
        
        &__heading {
            position : initial;
            order: 0;
            margin-bottom: 40px;
        }

        &__title.home__title {

            &:before {
                width: 60px;
                height: 14px;
                left: 0;
            }
        }

        &__intro {
            margin: 0;
        }

        &__buttons {
            margin-top: 10px;
            margin-left: 0;
        }

        &__btn {
            height: 50px;
        }

        &__container {
            flex-direction: column;

            &__sw {
                order: 1;
            }
        }

        #ff-stream-1 {
            width: 100%!important;
            height: 1225px!important;
            min-height: 1225px!important;
            min-width: unset!important;

            .ff-stream-wrapper {
                width: 100%!important;
                height: 1225px!important;

                .ff-item {
                    &:nth-child(3) {
                        transform: translate3d(0px, 830px, 0px) scale3d(1, 1, 1)!important;
                    }
                }
            }
        }
    }
}
.breadcrumb {

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 90px;
    }

    li {
        display: flex;
        align-items: center;
        position: relative;
        color: $color-text;
        padding: 0 15px;

        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            bottom: 0;
            right: -10px;
            margin: auto 0;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/ic_chevron_arianne.svg');
            background-position: center;
            background-size: contain;
        }

        & a {
            color: $color-text;
            transition: all $duration ease-in-out;
        
            &:hover {
                color: $color-main;
                text-decoration: underline $color-main;
            }
        }

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            &:before {
                content: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}

@keyframes menuClose {
    from {
        display: none;
        opacity: 0;
    }
    20% {
        display: flex;
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-main {
    z-index: 0;

    &__container {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        z-index: 0;
        right: 0;
        top: 0;
        transform: translateY(-100vh);
        height: 100vh;
        width: 100vw;
        background: $color-light;
        overflow: hidden;
        transition: .5s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: calc(50% - 215px);
            background: $color-main;
        }

        .container {
            position: relative;
            width: 100%;

            &:before, &::after {
                content: "";
                position: absolute;
                width: 330px;
                height: 8px;
                background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_animation/vague_petite.png');
                background-repeat: repeat-x;
                animation-name: waveAnim;
                animation-duration: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }

            &:before {
                left: calc(100vw - 520px);
                bottom: 115px;
            }

            &:after {
                left: calc(100vw - 550px);
                bottom: 100px;
            }

            @keyframes waveAnim {
                from { 
                    background-position-x: 0;
                }
                to { 
                    background-position-x: 30px;
                }
            }
        }
    }

    &__logo {
        position: absolute;
        top: 75px;

        svg {
            width: 270px;
            height: 39px;

            path[fill="#0A5A6A"] {
                fill: $color-white;
            }
        }
    }

    .menu__nav__list {
        z-index: 15;
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        width: 325px;
        height: auto;
        margin-top: 185px;
        transition: all $duration ease-in-out;

        .nav-main-item {

            &.active, &:hover {

                .nav-main-link {
                    padding-left: 15px;

                    &:after {
                        opacity: 1;
                    }
                }
            }

            &.active {
                .sub-menu {
                    display: flex;
                }
                
                &.menu-item-has-children {
                    .nav-main-link {
                        &:before {
                            opacity: 1;
                            background-position-x: 0;
                        }
                    }
                }
            }

            a {
                transition: all 1.7s ease-in-out;
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                font-family: $font-family--heading;
                font-weight: $font-weight-normal;
                font-size: 1.25rem;
                line-height: 1.25rem;
                width: 100%;
                opacity: 1;
                margin-bottom: 35px;
                color: $color-white;
                text-transform: uppercase;
                transition: all $duration ease-in-out;

                span {
                    text-transform: lowercase;
                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 85px;
                    height: 20px; 
                    top: 50%;
                    left: 330px;
                    transform: translateY(-50%);
                    background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_titre_home.svg');
                    background-size: cover;
                    background-position-x: -85px;
                    background-repeat: no-repeat;
                    opacity: 0;
                    transition: all .5s ease-in-out;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto 0;
                    width: 7px;
                    height: 16px;
                    background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/points_titre_home.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    opacity: 0;
                    transition: all .5s ease-in-out;
                }
            }
    
            .sub-menu {
                display: none;
                position: absolute;
                top: 0;
                left: 510px;
                width: 300px;
                transition: all $duration ease-in-out;

                .nav-drop {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    position: relative;
                    max-height: 565px;
                    columns: 300px 2;
                    gap: 10px 65px;

                    &__title {
                        font-family: $font-family--heading;
                        font-weight: $font-weight-normal;
                        font-size: 20px;
                        line-height: 20px;
                        position: absolute;
                        color: $color-main;
                        transform: rotate(-90deg);
                        transform-origin: right;
                        top: -10px;
                        left: -365px;
                        opacity: .4;
                        text-transform: uppercase;
                        width: 320px;
                        height: 20px;
                        text-align: end;
                    }
                }

                .nav-child-item, .nav-grandchild-item {
                    position: relative;
                    font-family: $font-family--heading;
                    font-weight: $font-weight-light;
                    font-size: $font-size--5;
                    line-height: 20px;
                    color: $color-text;
                    transition: all $duration ease-in-out;

                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding-bottom: 20px;
                        z-index: 3;
                        transition: all $duration ease-in-out;
                    }

                    &:hover {
                        margin-left: 15px;

                        a {
                            color: $color-main;
                        }
                    }
                }
            }

            &.active,
            &.menu-item-has-children.active {
                .sub-menu {
                    display: block;
                }
            }
        }
    }

    &__button {
        display: none;

        ~ .menu {
            clear: both;
            transform: scaleY(0);
            transform-origin: 50% 0;
            transition:transform .3s ease;
        }

        &:checked {
            & ~ .nav-main__container {
                transform: translateY(0);

                .container .menu {
                    transform: scaleY(1);
                    display: flex;

                    li a {
                        animation: slide-left 1.2s ease-in-out 0s 1 normal forwards;
    
                        @keyframes slide-left {
                            from {
                                opacity: 0;
                                //transform: translateX(-650px);
                            }
                        
                            to {
                                opacity: 1;
                                //transform: translateX(0);
                            }
                        }
                    }
                }
            }

            & ~ .nav-main__icon {

                .nav-main__icon--container {
                    &:after {
                        content: "Fermer";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .nav-main__icon__svg-container {
                    width: 0;

                    &:before, &:after {
                        top: 5px;
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    &__icon {
        position: relative;
        z-index: 1;
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        margin-left: 0;
        background-color: transparent;
        transition: all $duration ease-out;

        &--container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            background-color: transparent;
            width: 40px;
            height: 50px;
            text-transform: uppercase;
            transition: all $duration ease-out;

            &:after {
                content: "Menu";
                color: $color-text;
                font-weight: $font-weight;
                font-size: $font-size--text-small;
                line-height: $font-size--text-small;
            }

            &:hover {
                color: $color-text;
                span {
                    background-color: $color-second;

                    &:before, &:after {
                        background-color: $color-second;
                    }
                }
            }
        }    
    
        &__svg-container {
            position: relative;
            display: block;
            height: 2px;
            width: 25px;
            top: 10px;
            background-color: $color-main;
            transition: all $duration ease-out;
    
            &:before, 
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                height: 100%;
                width: 36px;
                background-color: $color-main;
                transition: all $duration ease-out;
            }
    
            &:before {
                top: 8px;
            }
    
            &:after {
                top: -8px;
            } 
        }
    }
}


// Responsive

// 1200
@media screen and (max-width: $large) {

    .nav-main {

        &__logo {
            top: 65px;
        }

        &__container {
            &:before {
                width: calc(50% - 140px);
            }

            .container {
                &:before {
                    left: calc(100vw - 240px);
                }

                &:after {
                    left: calc(100vw - 270px);
                }
            }
        }

        .menu__nav__list {
            width: 300px;
            margin-top: 155px;

            .nav-main-item {
                .nav-main-link {
                    font-size: 1.125rem;

                    &:before {
                        width: 40px;
                        height: 10px;
                        left: 300px;
                    }
                }

                .sub-menu {
                    left: 400px;
                    width: 250px;

                    .nav-drop {
                        columns: 250px 2;
                        gap: 10px 40px;
                        max-height: 540px;

                        &__title {
                            font-size: 1.125rem;
                            left: -350px;
                        }
                    }
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .nav-main {

        &__logo {
            display: flex;
            align-items: center;
            top: 50px;
        }

        &__container {
            overflow: hidden auto;
            background: $color-main;
            
            &:before {
                content: unset;
            }

            .container {
                &:before, &:after {
                    content: unset;
                }
            }
        }

        &__button {
            &:checked {

                & ~ .nav-main__icon {

                    .nav-main__icon--container {
                        &:after {
                            color: $color-white;
                        }
                    }

                    .nav-main__icon__svg-container {
                        &:before, &:after {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }

        .menu__nav__list {
            margin: 120px 0 0;
            padding-top: 15px;
            width: 100%;

            .nav-main-item {
                .nav-main-link {
                    display: inline-block;
                    width: auto;

                    &:before {
                        content: unset;
                    }
                }

                &.menu-item-has-children {

                    & .nav-main-link:after {
                        content: "";
                        width: 50px;
                        height: 50px;
                        top: 0;
                        left: unset;
                        right: -40px;
                        bottom: 5px;
                        margin: auto 0;
                        opacity: 1;
                        background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/chevron_responsive.svg');
                    }
                }

                .sub-menu {
                    z-index: 1;
                    display: block;
                    height: 100%;
                    width: 100%;
                    left: 100vw;
                    padding: 15px;
                    background-color: $color-main;
                    transition: all .5s ease-in-out;

                    .nav-drop {
                        gap: unset;
                        columns: unset;
                        max-height: unset;
                        width: 100%;

                        .nav-drop__title {
                            position: relative;
                            top: unset;
                            left: unset;
                            opacity: 1;
                            width: 100%;
                            height: auto;
                            text-align: left;
                            transform: unset;
                            color: $color-second;
                            padding-left: 30px;
                            margin-bottom: 30px;
                            cursor: pointer;

                            &:after {
                                content: "";
                                position: absolute;
                                width: 50px;
                                height: 50px;
                                top: 0;
                                left: -15px;
                                bottom: 0;
                                margin: auto 0;
                                opacity: 1;
                                background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/chevron_responsive.svg');
                                transform: rotate(180deg);
                            }
                        }

                        li {
                            color: $color-white;
                            height: auto;
                            padding-bottom: 30px;
                            
                            a {
                                padding: 0;
                                color: $color-white;

                                &:hover, &:focus {
                                    color: $color-white;
                                }
                            }
                        }
                    }

                    .nav-child-item, .nav-grandchild-item {
                        &:hover {
                            margin: 0;
                            color: $color-white;

                            a {
                                color: $color-white;
                            }
                        }
                    }
                }

                &.active {
                    .nav-main-link {
                        padding: 0;
                        color: $color-white;
                    }

                    .sub-menu {
                        left: -15px;
                    }
                }

                &:hover {

                    .nav-main-link {
                        padding: 0;
                        color: $color-white;

                        &:before, &:after {
                            content: unset;
                        }
                    }
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .nav-main {

        &__logo {
            top: 35px;

            & svg {
                width: 165px;
                height: 24px;
            }
        }

        &__icon {
            margin-top: 17px;
            transition: all $duration ease;

            &--container {
                height: 35px;
                width: 30px;

                &:after {
                    font-size: .625rem;
                    line-height: .625rem;
                    transition: color $duration ease;
                }
            }

            &__svg-container {
                width: 18px;
                top: 5px;

                &:before, &:after {
                    width: 28px;
                }

                &:before {
                    top: 6px;
                }

                &:after {
                    top: -6px;
                }
            }
        }

        &__button:checked {
            & ~ .nav-main__icon {
                position: absolute;
                top: 3px;
                right: 10px;
                width: max-content;
                margin-top: 0;
            }
        }

        .menu__nav__list {
            margin-top: 90px;

            .nav-main-item {
                &.active {
                    & .sub-menu {
                        left: 0;
                    }
                }
            }
        }
    }

}
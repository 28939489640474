//===================================================================
// Google translate - GTranslate.io
//===================================================================

.tools {
    &__translate {
        position: relative;
    
        &__wrapper {
            position: absolute;
            left: 50%;
            top: 65px;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-light;
            border-radius: 20px;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px #0A5A6A);

        
            &.js-open {
                display: flex;
            }
    
            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-light;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }
    
            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
        
                &:hover, &:focus {
                    text-decoration: underline;
                    color: $color-main;
                }
        
                &.gt-current-lang {
                    color: $color-main;
                    font-weight: $font-weight-normal;
                    text-decoration: underline;
                }
            }
        }
    
        &__close {
            position: absolute;
            left: 50%;
            bottom: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: {
                property: background-color, border-color;
                duration: $duration;
                timing-function: ease;
            }
    
            svg {
                width: 26px;
                height: 26px;
                fill: $color-text;
                transition: fill $duration ease;
            }
    
            &:hover, &:focus {
                background-color: $color-main;
                border-color: $color-main;
    
                svg {
                    fill: $color-white;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// // 1200
// @media screen and (max-width: $large) {

// }


// // 960
// @media screen and (max-width: $medium) {

// }


// 640
@media screen and (max-width: $small) {

    .tool--translate {
        & svg {
            width: 20px;
            height: 20px;
        }
    }

    .tools__translate__wrapper {
        top: 50px;
    }

}

.cover {
    position: relative;

    &__image-wrapper {
        position: relative;
        z-index: 1;
        width: calc(100% - 170px);
        height: 430px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        position: relative;
        z-index: 0;
        background: $color-white;
        padding: 210px 100px 50px 85px;
        margin-top: -170px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
            height: 100%;
            width: calc(50vw + 585px);
            background-color: $color-main;
        }
    }

    &__title {
        color: $color-white;
        margin: 0 0 10px;
    }

    &__category {
        color: $color-white;
    }
    
    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-medium;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }

    &__intro {
        color: $color-white;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 400px;
            width: calc(100% - 130px);
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 385px;
            width: 100%;
        }

        &__title, &__lead-wrapper, &__category {
            max-width: 620px;
            margin: 0 auto;
        }

        &__informations {
            width: 100vw;
            left: 50%;
            right: unset;
            padding: 210px 50px 50px;
            transform: translateX(-50%);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {        
        &__image-wrapper {
            z-index: 1;
            height: 300px;
            width: 100vw;
            left: 50%;
            transform: translateX(-50%);
        }

        &__title, &__lead-wrapper, &__category {
            width: 100%;
        }
    }

}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    height: 180px;
    transition: all .6s ease-in-out;
}

.header-top {
    position: fixed;
    background-color: $color-white;
    width: 100%;
    transition: all .6s ease-in-out;

    .container--identity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        margin: 50px auto;
        transition: all .6s ease-in-out;

        .site-identity {
            display: flex;
            align-items: center;
        }

        .identity {
            &__title {
                font-size: 0;
                line-height: 0;
                margin: 0;
                transition: $duration;

                & svg {
                    width: 370px;
                    height: 55px;
                    transition: all $duration ease-in-out;
                }

                &:hover {
                    cursor: pointer;
                    color: $color-second;
                }

                &:before {
                    content: unset;
                }
            }
        }

    }

    .nav-tool {
        z-index: 0;
        display: flex;
        align-items: center;
    }
}

// Header fixed
header.header--fixed {
    height: 80px;

    .header-top {
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

        .container--identity {
            margin: 0 auto;

            .identity {
                &__title {
                    & svg {
                        width: 270px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .tools {

        &__content {
            width: 180px;
            gap: 15px;
        }

        &__search {
            right: 0;
            opacity: 1;
            pointer-events: auto;
        }

        &__bar__search {
            pointer-events: none;
            margin-left: 0;

            .searchInput {
                width: 50px;
                height: 50px;
                font-size: 0;
            }

            .search-svg {
                width: 50px;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    z-index: 1;
    margin-right: 35px;

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        width: 660px;
        height: 60px; 
        background: $color-bg--transparent;
        transition: all .6s ease-in-out;

        & > svg {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-text; 
        }
    }

    .tool {
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0;
        padding: 0;
        background-color: $color-bg--transparent;
        border: $border-width $border-style $color-gray;
        border-radius: $border-radius--round;
        transition: all .6s ease-in-out;
        
        svg {
            fill: $color-text;
            transition: all .6s ease-in-out;
        }

        &:hover {
            cursor: pointer;
            background-color: $color-second;
            border-color: $color-second;
        }
    }

    &__accessibility {
        display: flex;
        
        svg {
            width: 26px;
            height: 26px;
        }
    }

    &__search {
        position: absolute;
        z-index: 1;
        width: 50px;
        height: 50px;
        right: 5px;
        opacity: 0;
        border: 0!important;
        pointer-events: none;
        transition: all .6s ease-in-out;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__bar__search {
        margin-left: 10px;
        pointer-events: auto;
        transition: all .6s ease-in-out;
        
        .searchInput {
            border-radius: 60px;
            padding: 20px 24px;
            width: 530px;
            height: 60px;
            font-size: $font-size--text-small;
            transition: all .6s ease-in-out;
        }

        .search-container {
            .search-svg {
                width: 60px;
                transition: all .6s ease-in-out;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: $color-text;
                    transition: all .6s ease-in-out;
                }

                &:hover {
                    svg {
                        fill: $color-second;
                    }
                }
            }

            .searchInput {
                border-color: $color-gray!important;

                &:hover, &:focus {
                    border-color: $color-main!important;
                }
            }
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    header {
        height: 160px;
    }

    .header-top {
        .container {
            &--identity {
                margin: 40px auto;
            }
        }
    }

    header.header--fixed .tools__content {
        gap: 10px;
        width: 170px;
    }

    .tools {

        & .tool {
            &:hover {
                border-color: $color-second!important;
            }
        }

        &__content {
            width: 170px;
            justify-content: flex-start;
        }

        &__search {
            opacity: 1;
            pointer-events: auto;
            right: 0;
            border: $border-width $border-style $color-gray!important;
        }

        &__bar__search {
            display: none;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    header {
        height: 140px;
    }

    .header-top {
        .container {
            &--identity {
                height: 80px;
                margin: 30px auto;

                .identity {
                    &__title {
                        & svg {
                            width: 310px;
                            height: 47px;
                        }
                    }
                }
            }
        }
    }

    .tools {
        z-index: unset;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    header {
        height: 140px;
    }

    .header-top {
        position: relative;

        & .container {
            &--identity {
                height: 90px;
                margin: 0 auto;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & .identity {
                    &__title {

                        & svg {
                            width: 272px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }

    .tools {
        margin-right: 15px;

        &__content {
            width: 115px;
            height: auto;
            margin-top: 15px;
            gap: 5px;
        }

        &__accessibility {
            & svg {
                width: 20px;
                height: 20px;
            }
        }

        &__search {
            & svg {
                width: 15px;
                height: 15px;
            }
        }

        .tool {
            width: 35px;
            height: 35px;
        }
    }

    // header.header--fixed {
    //     height: 60px;

    //     .header-top {
    //         .container {
    //             &--identity {
    //                 height: 60px;

    //                 .identity {
    //                     &__title {
                            
    //                         & svg {
    //                             width: 165px;
    //                             height: 24px;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     .tools {
    //         &__content {
    //             width: 75px;
    //         }
    //     }
    // }

    .container--identity {
        position: relative;
    }

}

.footer {

    .container {
        display: flex;
        flex-direction: column;
    }

    &__infos {
        display: flex;
        justify-content: space-between;

        &__logo {
            margin-top: 80px;
        }

        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            margin: 0 0 20px;
        }
        
        &__phone {
            font-weight: $font-weight-bold;
        }
        
        &__container {
            display: flex;
            z-index: 1;
            margin-top: -35px;
            padding: 65px 130px 75px 100px;
            background-color: $color-light;
            width: 870px;
            height: 355px;
        }
        
        .button {
            margin: 20px 0 0;
            padding: 15px 25px;
        }

        &__coordonnees {
            display: flex;
            flex-direction: column;
            width: 180px;
            margin-right: 155px;

            .footer__infos__text {
                margin-bottom: 10px;
            }
        }

        &__horaires {
            width: 280px;
        }
    }

    &__menu {
        margin-top: 25px;
        padding: 30px 0;

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 57px 0 0;
            font-size: $font-size--text-small;

            a {
                &:hover {
                        opacity: .6;
                }
            }
        }
    }

    &__labels {
        position: relative;
        height: 60px;
        margin-top: 15px;
        margin-bottom: 45px;

        &:before, &::after {
            content: "";
            position: absolute;
            width: 330px;
            height: 8px;
            background-image: url('/wp-content/themes/montmorillon/assets/src/images/sprite-images/Pictos/vague_animation/vague_petite.png');
            background-repeat: repeat-x;
            animation-name: waveAnim;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        &:before {
            left: calc(50% + 450px);
            bottom: 35px;
        }

        &:after {
            left: calc(50% + 420px);
            bottom: 20px;
        }

        &__list {
            display: flex;
            align-items: center;
        }

        &__item {
            margin-right: 60px;
            
            & img {
                filter: grayscale(1);
                transition: all $duration ease-in-out;
            }

            &:hover {
                cursor: pointer;

                & img {
                    filter: grayscale(0);
                }
            }
        }

        &__button {
            margin: 0 0 0 30px;

            &__link {
                color: $color-main;
                border-color: $color-main;
                margin: 0;

                &:hover {
                    border-color: $color-second;
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {

        &__infos {

            &__logo {
                margin-top: 70px;

                & svg {
                    width: 180px;
                    height: 128px;
                }
            }

            &__container {
                height: 320px;
                margin-top: -50px;
                padding: 50px 75px 55px;
                width: 700px;
            }

            &__coordonnees {
                margin-right: 70px;
            }

            &__horaires {
                width: 300px;
            }

            .button {
                padding: 15px;
            }
        }

        &__menu {
            margin-top: 20px;

            li {
                font-size: 0.875rem;
                padding: 0 50px 0 0;

                &:nth-child(6) {
                    padding: 0;
                }
            }
        }

        &__labels {

            &:before {
                left: calc(50% + 450px);
            }

            &:after {
                left: calc(50% + 420px);
                bottom: 15px;
            }
            
            &__item {

                &:last-child {
                    margin-right: 50px;
                }
            }

            &__button {
                margin: 0;
            }
        }
    }


}

// 960
@media screen and (max-width: $medium) {

    .footer {

        &__infos {

            &__logo {
                display: none;
            }

            &__container {
                width: 620px;
                height: 330px;
                padding: 60px 50px 60px 40px;
            }

            &__coordonnees {
                margin-right: 50px;
            }
        }

        &__menu {
            margin: 40px 0;
            padding: 0;

            &__item {
                &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                    padding-bottom: 30px;
                }
            }
        }

        &__labels {
            margin: 0 0 40px;

            &:before, &::after {
                content: unset;
            }

            &__item {
                margin-right: 15px;
            }

            &__button {
                margin-left: 18px;

                &__link {
                    padding: 15px 20px;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {

        &__infos {
            &__container {
                flex-direction: column;
                width: 320px;
                height: 600px;
                margin-left: -10px;
                padding: 50px 35px;
            }

            &__title {
                margin: 0 0 15px;
            }

            &__text {
                line-height: 1.375rem;
            }

            &__coordonnees {    
                margin: 0 0 50px;
            }

            &__horaires {
                width: 100%;
            }
        }

        &__menu {

            &__item {

                &:nth-child(2) {
                    padding-right: 0;
                }

                &:nth-child(4) {
                    padding-bottom: 30px;
                }
            }
        }

        &__labels {
            margin: 0 0 35px;
            height: 140px;

            &__list {
                flex-wrap: wrap;
            }

            &__button {
                margin: 0;
            }

            &__item {

                &:nth-child(3) {
                    margin-right: 0;
                }

                &:nth-child(5) {
                    margin-right: 20px;
                }
            }
        }
    }

}